<template>
  <div>
    <b-modal ref="modal_qr" hide-footer hide-header>
      <div>
        <b-row>
          <b-col>
            <!-- <img :src="$store.state.qr" alt="loading..." />  -->
            <progressive-img :src="$store.state.qr" :placeholder="loading" aspect-ratio="1.5" :blur="0" />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center mt-3">
            <b-col class="mb-2 font-weight-bold"><b-icon icon="basket-fill" class="mr-1"></b-icon>{{ "รวมเป็นเงิน: " +
              price }}</b-col>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center mt-3">
            <b-button variant="success" class="text-left mb-1" @click="reloadPage">ชำระเงินเรียบร้อย</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <div class="header pb-6 pt-5 pt-lg-8 d-flex align-items-center profile-header" :style="'min-height: 700px;background-size: cover;background-position: center top;background-image:' +
      `url(${require('@/assets/bgclimb.webp')})`
      ">
      <b-container fluid>
        <!-- Mask -->
        <span style="background-color: #8f384d" class="mask opacity-8"></span>
        <!-- Header container -->
        <b-container fluid class="d-flex align-items-center"> </b-container>
      </b-container>
    </div>
    <!-- <b-container fluid style="margin-top: -600px" v-if="!$store.state.username">
      <b-row>
        <b-col class="text-center mb-3">
          <b-button variant="success" to="/login">เข้าสู่ระบบเพื่อรับข้อสอบเต็มจำนวน</b-button>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col>
          <div class="gallery-container gallery-garments hide" style="height: 200px">
            <div class="gallery-item" v-for="(image, i) in subject_cover" :key="i">
              <img height="200" width="200" class="image" :src="image" @click="onClick(i)" />
            </div>
            <vue-gallery-slideshow :images="subject_cover" :index="index" @close="index = null"></vue-gallery-slideshow>
          </div>
        </b-col>
      </b-row>
    </b-container> -->

    <b-container fluid style="margin-top: -600px" v-if="statePayment == 0">

      <b-row class="mt-4">
        <b-col cols="12">
          <b-button variant="warning" class="mb-1" size="sm"
            @click="scrollToElement('scrollToSelectAddOn', true)">ซื้อเฉลยละเอียด</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" xl="4" v-for="(item, index) in pricing" :key="index" @click="
          clickPayment(index);
        scrollToElement('scrollToSelectAddOn', packageSelected);
        " class="pb-1">
          <card>
            <b-card :bg-variant="item.selected ? 'success' : ''" border-variant="dark" header-bg-variant="primary"
              class="text-center">
              <b-card-text v-if="item.days === 90" class="text-right"
                style="margin-bottom: 0px; margin-top: -26px"><b-icon icon="shield-fill-check" class="mr-1"
                  variant="success"></b-icon>สอบไม่ผ่านคืนเงิน</b-card-text>
              <b-row>
                <b-col>
                  <h1>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.91 4.91a1.91 1.91 0 01-1.044 1.701c.942 2.366 1.928 3.53 2.795 3.622.982.104 1.88-.323 2.76-1.377a.977.977 0 01.072-.078 1.91 1.91 0 111.468.873l-1.423 5.42c-.297 1.13-1.363 1.922-2.586 1.922H8.066c-1.223 0-2.29-.792-2.586-1.922L4.063 9.675a1.91 1.91 0 111.46-.898c.03.028.059.06.086.093.837 1.048 1.727 1.471 2.748 1.363.908-.096 1.888-1.253 2.793-3.614a1.91 1.91 0 112.76-1.71zM6.561 19.008h10.875c.518 0 .938.448.938 1s-.42 1-.938 1H6.563c-.517 0-.937-.448-.937-1s.42-1 .937-1z"
                        fill="#fbbe28"></path>
                    </svg>
                    {{ item.name }}
                  </h1>
                  <div>
                    <span style="color: #082032; font-size: 18px">
                      เฉลี่ยเพียง
                      <span style="font-size: 30px"><b>{{ item.avg }}</b></span>
                      บาท/เดือน
                    </span>
                    <br />
                    <span> "ข้อสอบพร้อมเฉลยถูกผิดทุกวิชา" </span>
                    <div>
                      <b v-if="item.addOns !== 0" style="color: green">แถม !! {{ "เฉลยละเอียด " + item.addOns + " วิชา"
                        }}</b>
                    </div>
                    <b style="color: green">
                      {{ item.days === 90 ? "ชีทอบรม 5 วิชา" : "" }}
                    </b>
                    <br v-if="item.days < 60" />
                    <br v-if="item.days < 90" />
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </card>
        </b-col>
      </b-row>
      <card class="mt-2">
        <b-row class="text-center">
          <b-col>
            <b-row>
              <b-col class="mb-2 font-weight-bold">
                <b-icon icon="bag-fill" class="mr-1"></b-icon>
                {{ "ข้อสอบพร้อมเฉลยถูกผิดทุกวิชา" }}
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mb-2 font-weight-bold">
                <b-icon icon="bag-fill" class="mr-1"></b-icon>
                {{ "ซ้อมสอบจริงไม่จำกัด" }}
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mb-2 font-weight-bold">
                <b-icon icon="bag-fill" class="mr-1"></b-icon>
                {{ "รวบรวมสถิติการอ่าน" }}
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mb-2 font-weight-bold">
                <b-icon icon="badge-ad-fill" class="mr-1"></b-icon>
                {{ "ไม่มีโฆษณา" }}
              </b-col>
            </b-row>
            <b-row v-if="
              packageSelected &&
              packageSelected.days > 30 &&
              freeAddOnItem > 0
            ">
              <b-col class="mb-2 font-weight-bold text-left">
                <b-icon icon="patch-check-fill" class="mr-1"></b-icon>
                <span style="color:red">{{ "กรุณาเลือกเฉลยละเอียด " + freeAddOnItem + " วิชา" }}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row ref="scrollToSelectAddOn">
          <b-col cols="12">
            <!-- <card> -->
            <!-- Card body -->
            <!-- <div class="mb-2">เฉลยละเอียด</div> -->
            <b-card border-variant="dark" header-bg-variant="primary">
              เฉลยละเอียด วิชาพื้นฐาน
              <b-row align-v="center" class="text-nowrap mt-3" v-for="(item, index) in options" :key="item.value"
                @click="clickChecked(0, index)">
                <b-col cols="2" md="auto">
                  <base-button icon :type="item.checked ? 'success' : 'secondary'" style="
                      width: 40px;
                      height: 40px;
                      padding: 7px 7px;
                      font-size: 20px;
                      line-height: 1.33;
                      border-radius: 25px;
                    ">
                    <span class="btn-inner--icon"><i :class="getIcon(item.checked)"></i></span>
                  </base-button>
                </b-col>
                <b-col cols="10">
                  <h4 class="mb-0">
                    {{ item.text }}
                  </h4>
                  <p class="text-sm text-muted mb-0">
                    <strike style="color: red">{{ item.normal }}</strike>
                    &nbsp;
                    <span>{{ item.price + " ฿" }}</span>
                  </p>
                  <span class="text-success" v-show="item.disabled">● </span>
                  <small v-if="item.disabled">ซื้อแล้ว</small>
                </b-col>
              </b-row>
              <div class="mt-3">
                <span v-show="this['optionMore' + this.$store.state.department].length > 0">เฉลยละเอียด วิชาสาขา</span>
              </div>
              <div v-if="this.$store.state.department" class="mt-3">
                <b-row v-if="!$store.state.username">
                  <b-col>
                    <!-- <b-card> -->
                    <!-- <base-input label="⚙️ สาขา"> -->
                    <base-input>
                      <select class="form-control" v-model="department">
                        <option v-for="(t, index) in $store.state.department_names_2" :key="index" :value="index">
                          {{ t.text }}
                        </option>
                      </select>
                    </base-input>
                    <!-- </b-card> -->
                  </b-col>
                </b-row>
                <b-row align-v="center" class="text-nowrap" v-for="(item, index) in this[
                  'optionMore' + this.$store.state.department
                ]" :key="item.value" @click="clickChecked(1, index)">

                  <b-col cols="2" md="auto">
                    <base-button icon :type="item.checked ? 'success' : 'secondary'" style="
                      width: 40px;
                      height: 40px;
                      padding: 7px 7px;
                      font-size: 20px;
                      line-height: 1.33;
                      border-radius: 25px;
                    ">
                      <span class="btn-inner--icon"><i :class="getIcon(item.checked)"></i></span>
                    </base-button>
                  </b-col>
                  <b-col cols="10">
                    <h4 class="mb-0">
                      {{ item.text }}
                    </h4>
                    <p class="text-sm text-muted mb-0">
                      <strike style="color: red">{{ item.normal }}</strike>
                      &nbsp;
                      <span>{{ item.price + " ฿" }}</span>
                    </p>
                    <span class="text-success" v-show="item.disabled">● </span>
                    <small v-show="item.disabled">ซื้อแล้ว</small>
                  </b-col>
                </b-row>
                <!-- <span v-show="this['optionMoreSheet' + this.$store.state.department].length > 0">ชีทสรุปรายวิชา</span> -->
                <div class="mt-3">
                  ชีทสรุปรายวิชา
                </div>

                <b-row align-v="center" class="text-nowrap mt-3" v-for="(item, index) in this[
                  'optionMoreSheet' + this.$store.state.department
                ]" :key="item.value" @click="clickChecked(3, index)">

                  <b-col cols="2" md="auto">
                    <base-button icon :type="item.checked ? 'success' : 'secondary'" style="
                      width: 40px;
                      height: 40px;
                      padding: 7px 7px;
                      font-size: 20px;
                      line-height: 1.33;
                      border-radius: 25px;
                    ">
                      <span class="btn-inner--icon"><i :class="getIcon(item.checked)"></i></span>
                    </base-button>
                  </b-col>
                  <b-col cols="10">
                    <h4 class="mb-0">
                      {{ item.text }}
                    </h4>
                    <p class="text-sm text-muted mb-0">
                      <strike style="color: red">{{ item.normal }}</strike>
                      &nbsp;
                      <span>{{ item.price + " ฿" }}</span>
                    </p>
                    <span class="text-success" v-show="item.disabled">● </span>
                    <small v-show="item.disabled">ซื้อแล้ว</small>
                  </b-col>
                </b-row>
              </div>

            </b-card>
          </b-col>
          <b-col cols="12" class="mt-3">
            <!-- <card> -->
            <!-- Card body -->
          </b-col>
          <b-col cols="12" class="mt-3">
            <!-- <card> -->
            <!-- Card body -->
            สอบอบรมหลังสอบ กว.
            <b-row align-v="center" class="text-nowrap mt-3" v-for="(item, index) in optionSheet" :key="item.value"
              @click="clickChecked(2, index)">
              <b-col cols="2" md="auto">
                <base-button icon :type="item.checked ? 'success' : 'secondary'" style="
                    width: 40px;
                    height: 40px;
                    padding: 7px 7px;
                    font-size: 20px;
                    line-height: 1.33;
                    border-radius: 25px;
                  ">
                  <span class="btn-inner--icon"><i :class="getIcon(item.checked)"></i></span>
                </base-button>
              </b-col>
              <b-col cols="10">
                <h4 class="mb-0">
                  {{ item.text }}
                </h4>
                <p class="text-sm text-muted mb-0">
                  <!-- <strike style="color: red">{{ item.normal }}</strike>
                  &nbsp; -->
                  <span>{{ item.price + " ฿" }}</span>
                </p>
                <span class="text-success" v-if="
                  item.disabled && userPackage.subject.includes(item.value)
                ">●
                </span>
                <small v-if="
                  item.disabled && userPackage.subject.includes(item.value)
                ">ซื้อแล้ว</small>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-4" v-if="packageSelected && packageSelected.days === 90">
          <b-col cols="12">
            <b-button variant="outline-primary" class="text-left mb-1" size="sm"
              @click="clickRefund">*เงื่อนไขการคืนเงิน</b-button>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col class="mb-2 font-weight-bold" sm="12" xl="6">
            <b-icon icon="basket-fill" class="mr-1"></b-icon>
            {{ "รวมเป็นเงิน: " }}
            <strike v-if="packageSelected" style="color: red">{{
              packageSelected.normal ? packageSelected.normal : 0
            }}</strike>
            &nbsp;
            <span>{{ price + " ฿" }}</span>
          </b-col>
          <b-col sm="12" xl="6" class="text-right">
            <b-button size="md" variant="success" v-if="parseInt(price) !== 0"
              @click="showModalConfirm()">ชำระเงิน</b-button>
          </b-col>
        </b-row>
      </card>
    </b-container>

    <b-container fluid style="margin-top: -600px" v-if="statePayment == 1">
      <card>
        <b-row v-show="statePayment == 1">
          <b-col>
            <base-button size="sm" icon type="primary" @click="statePayment = 0">
              <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
            </base-button>
          </b-col>
        </b-row>
        <b-card class="mt-3">
          <b-row>
            <b-col>
              <div v-if="packageSelected">
                <b-row>
                  <b-col class="text-center mb-3"> สรุปแพ็คเกจ </b-col>
                </b-row>
                <b-row v-if="packageSelected">
                  <b-col class="mb-2 font-weight-bold">
                    <b-icon icon="bag-fill" class="mr-1"></b-icon>
                    {{ "แถมวันใช้งาน " + packageSelected.days }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="mb-2 font-weight-bold">
                    <b-icon icon="bag-fill" class="mr-1"></b-icon>
                    {{ "ซ้อมสอบจริงไม่จำกัด" }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="mb-2 font-weight-bold">
                    <b-icon icon="bag-fill" class="mr-1"></b-icon>
                    {{ "รวบรวมสถิติการอ่าน" }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="mb-2 font-weight-bold">
                    <b-icon icon="badge-ad-fill" class="mr-1"></b-icon>
                    {{ "ไม่มีโฆษณา" }}
                  </b-col>
                </b-row>
              </div>
              <!-- {{selectedAct}} -->
              <b-row v-for="index in selectedAct" :key="index">
                <!-- {{index}} -->
                <b-col class="mb-2 font-weight-bold">
                  <b-icon icon="bag-plus-fill" class="mr-1"></b-icon>
                  <span v-if="index < 999"> {{ "เฉลยละเอียด " + getSubjectText(index) }} </span>
                  <span v-else> {{ getSubjectText(index) }} </span>
                </b-col>
              </b-row>
              <b-row v-if="selectedSheetAct.length > 0">
                <b-col class="mb-2 font-weight-bold">
                  <b-icon icon="bag-plus-fill" class="mr-1"></b-icon>
                  {{ "ข้อสอบ + ชีทสอบอบรม 5 วิชา" }}
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col sm="8">
                  <base-input alternative name="code" prependIcon="ni ni-tag" placeholder="รหัสส่วนลด"
                    v-model="discount_code" v-on:keyup.enter="checkCodeStatus">
                    <template slot="success" v-if="codeStatus && codeStatus !== null">
                      <div class="text-success mt-2">
                        <b-icon class="mr-1" variant="success" icon="check-circle-fill"></b-icon>ใช้โค้ดสำเร็จ
                      </div>
                      <div class="mt-2">
                        **หากขึ้น "<span class="text">ใช้โค้ดสำเร็จ</span>"
                        สามารถชำระเงินได้เลย ระบบจะคำนวณส่วนลดอัตโนมัติ
                      </div>
                    </template>
                    <template slot="error" v-if="!codeStatus && codeStatus !== null">
                      <div class="text-warning mt-2">
                        <b-icon class="mr-1" variant="warning" icon="x-circle-fill"></b-icon>ไม่สามารถใช้รหัสได้
                      </div>
                    </template>
                  </base-input>
                </b-col>
                <b-col sm="4">
                  <b-button variant="success" @click="checkCodeStatus">
                    ใช้โค้ด
                  </b-button>
                </b-col>
              </b-row>
              <div v-show="!$store.state.username">
                <b-row class="mt-3">
                  <b-col>
                    <base-input alternative class="mb-3" prepend-icon="ni ni-email-83"
                      placeholder="Email รับรหัส Activate" name="Email" :rules="{ required: true, email: true }"
                      v-model="email"></base-input>
                  </b-col>
                </b-row>
                <b-row v-if="isValidEmail">
                  <b-col>
                    <div class="text-success mt-2">
                      <b-icon class="mr-1" variant="success" icon="check-circle-fill"></b-icon>
                      หากไม่ได้รับอีเมลล์ กรุณาติดต่อทีมงาน
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <div>
            <b-row>
              <b-col lg="3">
              </b-col>
              <b-col lg="6">
                <b-row>
                  <b-col class="mt-3">
                    <b-col class="mb-2 font-weight-bold"><b-icon icon="basket-fill" class="mr-1"></b-icon>{{
                      "ชำระผ่านพร้อมเพย์: " +
                      price
                    }} ฿</b-col>
                  </b-col>
                </b-row>
                <b-row class="ml-3">
                  <b-col>
                    (ฟรีค่าธรรมเนียม)
                  </b-col>
                </b-row>
                <b-row class="text-center mt-3">
                  <b-col>
                    <base-button icon type="success" @click="submitPay(info[0], true)" :disabled="!isValidEmail">
                      <img alt="Image placeholder" src="@/assets/promptpay.webp" height="22" />
                    </base-button>
                  </b-col>
                </b-row>
                <b-row class="text-center">
                  <b-col>
                    <span style="fontsize: 12px">ใช้เวลาตรวจสอบ 1-2 นาที</span>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col class="mt-3">
                    <b-col class="mb-2 font-weight-bold"><b-icon icon="basket-fill" class="mr-1"></b-icon>{{
                      "ชำระผ่านบัตรเครดิต/ช่องทางอื่น: " +
                      (price * 1.04).toFixed(2)
                    }} ฿</b-col>
                  </b-col>
                </b-row>
                <b-row class="ml-3">
                  <b-col>
                    (ค่าธรรมเนียม 4%)
                  </b-col>
                </b-row>
                <b-row class="text-center mt-3">
                  <b-col>
                    <base-button icon type="success" size="sm" :disabled="!isValidEmail" @click="submitPay(info[0])">
                      <img alt="Image placeholder" src="@/assets/card.svg" />
                      <span class="btn-inner--text">Banking</span>
                    </base-button>
                  </b-col>
                </b-row>
                <b-row class="text-center">
                  <b-col>
                    <span style="fontsize: 12px">ใช้เวลาตรวจสอบ 5-10 นาที</span>
                  </b-col>
                </b-row>
                <b-row class="text-center mt-3">
                  <b-col>
                    <img alt="Image placeholder" src="@/assets/bay.svg" />
                    <img alt="Image placeholder" src="@/assets/bbl.svg" />
                    <!-- <img alt="Image placeholder" src="@/assets/ktb.svg" />
            <img alt="Image placeholder" src="@/assets/scb.svg" /> -->
                    <!-- <img alt="Image placeholder" src="@/assets/card.svg" /> -->
                    <img alt="Image placeholder" src="@/assets/line.svg" width="24" height="24" />
                    <img alt="Image placeholder" src="@/assets/true.svg" />
                  </b-col>
                </b-row>
              </b-col>
              <b-co lg="3">
              </b-co>
            </b-row>
          </div>
        </b-card>
        <!-- <b-row class="mt-3">
          <b-col sm="8">
            <a class="text-danger"
              href="https://forms.gle/AdcKXzXB6hvsikJ57"
              target="_blank"
              >คลิก !! รับคูปองส่วนลด 5%</a
            >
          </b-col>
        </b-row> -->

      </card>
    </b-container>

    <b-modal v-model="showRefundModal" hide-footer centered>
      <template #modal-title>
        <h1>เงื่อนไขการคืนเงิน</h1>
      </template>
      <b-row>
        <b-col>
          <h3>กติกา</h3>
          <div class="refund-list">
            <b-row class="mt-2">
              <ul>
                <li>ใช้งานมากกว่า 60 ชั่วโมงขึ้นไป</li>
                <li>หัก 7% ของยอดซื้อเป็นค่าธรรมเนียม Transaction fee</li>
              </ul>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <modal :show.sync="classic" size="md">
      <div class="text-center">
        <!-- <h4> {{modals.new ? 'ข้อสอบใหม่' : 'ข้อสอบเก่า'}} </h4> -->
        <!-- <div class="mb-6 text-left">
              เลขข้อสีเขียว <b-button
                        style="border-radius: 50%"
                        size="sm"
                        variant="success"
                        > 
                      </b-button> = ข้อสอบที่ EnGenius สร้างขึ้นเพื่อให้ครอบคลุมเนื้อหาที่มีการอัพเดทใหม่

        </div> -->






        <h1>ซื้อ VOUCHER แบบไม่ต้อง Login ได้แล้ว</h1>
        <hr>
        <b-row class="text-left">
          <b-col>
            <h1>วิธีเปิดใช้งาน Premium</h1>
          </b-col>
        </b-row>
        <b-row class="text-left">
          <b-col>
            1. เข้าเว็บไซต์ https://engenius.neighborsoft.com
          </b-col>
        </b-row>
        <b-row class="text-left">
          <b-col>
            2. คลิก “สมัครสมาชิกใหม่” และกรอกรายละเอียดครบถ้วนเพื่อกด “ลงทะเบียน”
          </b-col>
        </b-row>
        <b-row class="text-left">
          <b-col>
            3. กรอก Activate Code ที่ได้รับ จากนั้น คลิกปุ่ม “ตรวจสอบ” เพื่อเริ่มใช้งานระบบพรีเมียม
          </b-col>
        </b-row>
        <img :style="'max-width: 100%;max-height: ' + windowHeight / 1.5 + 'px'"
          src="https://neighborsoft.com/wp-content/uploads/2024/06/activate_code.webp" />
      </div>
      <template slot="footer">
        <div>
          <base-button type="primary" size="sm" @click="classic = false">ปิด</base-button>
        </div>
      </template>
    </modal>

    <b-modal ref="modalConfirm" id="modalConfirm" hide-footer centered>
      <template #modal-title>
        <h1>ยังไม่ได้เลือกเฉลยละเอียด {{ freeAddOnItem }} วิชา</h1>
      </template>
      <b-row>
        <b-col>
          <h3>แน่ใจหรือไม่ที่จะชำระเงิน</h3>
          <b-row class="text-right">
            <b-col>
              <b-button size="md" variant="primary" @click="nextPayment()">แน่ใจ</b-button>
              <b-button size="md" variant="secondary" v-if="parseInt(price) !== 0"
                @click="closeModalConfirm()">ยกเลิก</b-button>
            </b-col>
          </b-row>

          <!-- <div>
            <b-row class="mt-2">
              <ul>
                <li>ใช้งานมากกว่า 45 ชั่วโมงขึ้นไป</li>
              </ul>
            </b-row>
          </div> -->
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
// import VueGallerySlideshow from "vue-gallery-slideshow";
import { getCheckCode } from "@/APIs";
import moment from "moment";
import scrollIntoView from "smooth-scroll-into-view-if-needed";

export default {
  // components: { VueGallerySlideshow },
  data() {
    return {
      classic: false,
      email: "",
      department: null,
      index: -1,
      subject_cover: [
        require("@/assets/s1.webp"),
        require("@/assets/s2.webp"),
        require("@/assets/s3.webp"),
        require("@/assets/s4.webp"),
        require("@/assets/s5.webp"),
        require("@/assets/s6.webp"),
        require("@/assets/s7.webp"),
        require("@/assets/s8.webp"),
        require("@/assets/s9.webp"),
        require("@/assets/s10.webp"),
      ],
      loading: require("@/assets/loading.gif"),
      pic1: require("@/assets/bgclimb.webp"),
      statePayment: 0,
      pricing: [
        {
          days: 30,
          name: "30 วัน",
          normal: "319",
          avg: "319",
          total: "319",
          addOns: 0,
          selected: false,
        },
        {
          days: 60,
          name: "60 วัน",
          normal: "836",
          avg: "259",
          total: "518",
          addOns: 2,
          selected: false,
        },
        {
          days: 90,
          name: "90 วัน",
          normal: "1113",
          avg: "239",
          total: "717",
          addOns: 4,
          selected: false,
        },
      ],
      info: [
        {
          color: "success",
          name: "SILVER",
          normal: 319,
          price: 239,
          detail: ["30 วัน", "ข้อสอบเฉลยทุกข้อ", "ไม่มีโฆษณา"],
        },
      ],
      options: [
        {
          text: "Engineering Static",
          normal: 119,
          price: 99,
          price2: 99,
          value: 2,
          disabled: false,
          checked: false,
        },
        {
          text: "Engineering Drawings",
          normal: 119,
          price: 99,
          price2: 99,
          value: 4,
          disabled: false,
          checked: false,
        },
        {
          text: "Engineering Materials",
          normal: 119,
          price: 99,
          price2: 99,
          value: 1,
          disabled: false,
          checked: false,
        },
        {
          text: "Computer Programming",
          normal: 119,
          price: 99,
          price2: 99,
          value: 3,
          disabled: false,
          checked: false,
        },
      ],
      optionMoreSheetDefault: [
        {
          text: "ชีทสรุป Engineering Materials",
          normal: 199,
          price: 159,
          price2: 159,
          value: 2001,
          disabled: false,
          checked: false,
        },
        {
          text: "ชีทสรุป Computer programming",
          normal: 199,
          price: 159,
          price2: 159,
          value: 2003,
          disabled: false,
          checked: false,
        },
      ],

      paymentID: "",
      packaged: false,
      addOnSelected: [],
      sheetSelected: [],
      packageSelected: null,
      price: 0,
      add_date: 0,
      discount_code: "",
      codeStatus: null,
      showRefundModal: false,
      optionMore0: [],
      optionMoreSheet0: [],
      optionMore1: [],
      optionMoreSheet1: [],
      optionMore2: [],
      optionMoreSheet2: [],
      optionMore3: [],
      optionMoreSheet3: [],
      optionMore4: [],
      optionMoreSheet4: [],
      optionMore5: [],
      optionMoreSheet5: [],
      optionMore6: [],
      optionMoreSheet6: [],
      optionMore7: [],
      optionMoreSheet7: [],
      optionMore8: [],
      optionMoreSheet8: [],
      optionMore9: [],
      optionMoreSheet9: [],
      optionSheet: [],
    };
  },
  mounted() {
    this.$store.commit("AUTHEN");
    this.initAll()
    if (!this.$store.state.username) {
      this.department = 0;
      setTimeout(() => {
        this.classic = true;
      }, 1000);
    }
    if (this.$route.query.code) {
      var payload = {
        username: this.$store.state.username,
        param: this.$route.query.code,
        code: this.$route.query.code,
      };
      this.$store.commit("POST_CLICK", payload);
    }
  },
  methods: {
    initAll() {
      this.optionMore0 = [
        {
          text: "Theory of Structures and Structural Analysis",
          normal: 119,
          price: 99,
          price2: 99,
          value: 107,
          disabled: false,
          checked: false,
        },
        {
          text: "Reinforced Concrete Design",
          normal: 119,
          price: 99,
          price2: 99,
          value: 7,
          disabled: false,
          checked: false,
        },
        {
          text: "Timber and Steel Design",
          normal: 119,
          price: 99,
          price2: 99,
          value: 8,
          disabled: false,
          checked: false,
        },
        {
          text: "Soil Mechanics",
          normal: 119,
          price: 99,
          price2: 99,
          value: 9,
          disabled: false,
          checked: false,
        },
      ]
      this.optionMoreSheet0 = [
        {
          text: "ชีทสรุป Theory of Structures",
          normal: 199,
          price: 159,
          price2: 159,
          value: 2107,
          disabled: false,
          checked: false,
        },
        {
          text: "ชีทสรุป Structural Analysis",
          normal: 199,
          price: 159,
          price2: 159,
          value: 2108,
          disabled: false,
          checked: false,
        },
        {
          text: "ชีทสรุป Soil Mechanics",
          normal: 199,
          price: 159,
          price2: 159,
          value: 2009,
          disabled: false,
          checked: false,
        },
      ]
      this.optionMore1 = [
        {
          text: "Heat Transfer",
          normal: 119,
          price: 99,
          price2: 99,
          value: 37,
          disabled: false,
          checked: false,
        },
        {
          text: "Mechanics of Machinery",
          normal: 119,
          price: 99,
          price2: 99,
          value: 19,
          disabled: false,
          checked: false,
        },
        {
          text: "Machine Design",
          normal: 119,
          price: 99,
          price2: 99,
          value: 24,
          disabled: false,
          checked: false,
        },
      ]
      this.optionMoreSheet1 = [
        {
          text: "ชีทสรุป Heat Transfer",
          normal: 199,
          price: 159,
          price2: 159,
          value: 2037,
          disabled: false,
          checked: false,
        },
        {
          text: "ชีทสรุป Machine Design",
          normal: 199,
          price: 159,
          price2: 159,
          value: 2024,
          disabled: false,
          checked: false,
        },
      ]
      this.optionMore2 = [
        {
          text: "ELECTRICAL MACHINES",
          normal: 119,
          price: 99,
          price2: 99,
          value: 45,
          disabled: false,
          checked: false,
        },
        {
          text: "Electric Power System Analysis",
          normal: 119,
          price: 99,
          price2: 99,
          value: 43,
          disabled: false,
          checked: false,
        },
        {
          text: "Electrical System Design",
          normal: 119,
          price: 99,
          price2: 99,
          value: 46,
          disabled: false,
          checked: false,
        }
      ]
      this.optionMoreSheet2 = [
        {
          text: "ชีทสรุป Electrical System Design",
          normal: 199,
          price: 159,
          price2: 159,
          value: 2046,
          disabled: false,
          checked: false,
        },
        {
          text: "ชีทสรุป Electrical Machine",
          normal: 199,
          price: 159,
          price2: 159,
          value: 2045,
          disabled: false,
          checked: false,
        },
      ]
      this.optionMore3 = []
      this.optionMoreSheet3 = []
      this.optionMore4 = []
      this.optionMoreSheet4 = []
      this.optionMore5 = []
      this.optionMoreSheet5 = []
      this.optionMore6 = []
      this.optionMoreSheet6 = []
      this.optionMore7 = [
        {
          text: "Solid Waste Engineering",
          normal: 119,
          price: 99,
          price2: 99,
          value: 89,
          disabled: false,
          checked: false,
        },
      ]
      this.optionMoreSheet7 = []
      this.optionMore8 = []
      this.optionMoreSheet8 = []
      this.optionMore9 = []
      this.optionMoreSheet9 = []
      this.optionSheet = [
        {
          text: "ข้อสอบ + ชีทสอบอบรม 5 วิชา",
          normal: 259,
          price: 259,
          price2: 259,
          value: 999,
          disabled: false,
          checked: false,
        },
      ]
      for (let i = 0; i < 10; i++) {
        this["optionMoreSheet" + i].push(this.optionMoreSheetDefault[0]);
        this["optionMoreSheet" + i].push(this.optionMoreSheetDefault[1]);
      }
      if (this.$store.state.username) {
        this.initAddOns();
      }
    },
    reloadPage() {
      window.location.reload();
    },
    showModalConfirm() {
      if (this.freeAddOnItem <= 0) this.nextPayment();
      else this.$refs["modalConfirm"].show();
      if (this.$route.query.code) {
        this.discount_code = this.$route.query.code;
        this.$nextTick(() => {
          this.checkCodeStatus();
        });
      }
    },
    closeModalConfirm() {
      this.$refs["modalConfirm"].hide();
    },
    nextPayment() {
      this.statePayment = 1;
      this.closeModalConfirm();
    },
    onClick(i) {
      this.index = i;
    },
    clickPayment(index) {
      for (let i = 0; i < this.pricing.length; i++) {
        if (i == index) {
          this.pricing[i].selected = !this.pricing[i].selected;
        } else {
          this.pricing[i].selected = false;
        }
      }
      this.initAddOns();
      if (this.pricing[index].selected) {
        this.packageSelectClick(this.pricing[index]);
      } else {
        this.packageSelected = null;
        this.updatePrice();
      }
    },
    clickChecked(id, index) {
      var item, array;
      if (id === 0) {
        item = this.options[index];
        array = this.addOnSelected;
      } else if (id === 1) {
        array = this.addOnSelected;
        item = this["optionMore" + this.$store.state.department][index];
      } else if (id === 2) {
        item = this.optionSheet[index];
        array = this.sheetSelected;
      } else if (id === 3) {
        // alert('click3')
        array = this.addOnSelected;
        item = this["optionMoreSheet" + this.$store.state.department][index];
      }
      if (item.disabled) return;
      var findIndex = array.findIndex((a) => a == item.value);
      if (array.includes(item.value)) {
        item.price = item.price2;
        array.splice(findIndex, 1);
      } else {
        array.push(item.value);
      }
      this.updateChecked(id);
      this.addOnSelectClick();
    },
    updateChecked(id) {
      if (id === 0) {
        for (let i = 0; i < this.options.length; i++) {
          const e = this.options[i];
          if (this.freeAddOnItem >= 0 && this.addOnSelected.includes(e.value)) {
            this.options[i].price = 0;
          }
          this.options[i].checked = this.addOnSelected.includes(e.value);
        }
      } else if (id === 1) {
        var optionMore = this["optionMore" + this.$store.state.department];
        for (let i = 0; i < optionMore.length; i++) {
          const e = optionMore[i];
          if (this.freeAddOnItem >= 0 && this.addOnSelected.includes(e.value)) {
            optionMore[i].price = 0;
          }
          optionMore[i].checked = this.addOnSelected.includes(e.value);
        }
      } else if (id === 2) {
        for (let i = 0; i < this.optionSheet.length; i++) {
          const e = this.optionSheet[i];
          this.optionSheet[i].checked = this.sheetSelected.includes(e.value);
        }
      } else if (id === 3) {
        var optionMoreSheet = this["optionMoreSheet" + this.$store.state.department];
        for (let i = 0; i < optionMoreSheet.length; i++) {
          const e = optionMoreSheet[i];
          // if (this.freeAddOnItem >= 0 && this.addOnSelected.includes(e.value)) {
          //   optionMoreSheet[i].price = 0;
          // }
          optionMoreSheet[i].checked = this.addOnSelected.includes(e.value);
        }
      }
    },
    getIcon(checked) {
      if (checked) return "ni ni-check-bold";
      else return "ni ni-fat-add text-gray";
    },
    clickRefund() {
      // console.log('Open Dialog Refund Rules.' + this.showRefundModal)
      this.showRefundModal = true;
    },
    initAddOns() {
      this.addOnSelected = this.userPackage.subject.slice();
      this.sheetSelected = [];
      this.options.forEach((element) => {
        element.price = element.price2;
        element.checked =
          this.userPackage.subject.indexOf(element.value) !== -1;
        element.disabled =
          this.userPackage.subject.indexOf(element.value) !== -1;
      });
      this.updateChecked(0);
      this["optionMore" + this.$store.state.department].forEach((element) => {
        element.price = element.price2;
        element.checked =
          this.userPackage.subject.indexOf(element.value) !== -1;
        element.disabled =
          this.userPackage.subject.indexOf(element.value) !== -1;
      });
      this.updateChecked(3);
      this["optionMoreSheet" + this.$store.state.department].forEach((element) => {
        element.price = element.price2;
        console.log(this.userPackage.subject)

        element.checked =
          this.userPackage.subject.indexOf(element.value) !== -1;
        element.disabled =
          this.userPackage.subject.indexOf(element.value) !== -1;

        console.log(element.checked)
        console.log(element.disabled)
      });
      this.updateChecked(1);
      this.optionSheet.forEach((element) => {
        let id = this.addOnSelected.indexOf(element.value);
        this.addOnSelected.splice(
          this.addOnSelected.indexOf(element.value),
          id !== -1 ? 1 : 0
        );
        if (id !== -1) this.sheetSelected.push(element.value);
        element.price = element.price2;
        element.checked =
          this.userPackage.subject.indexOf(element.value) !== -1;
        element.disabled =
          this.userPackage.subject.indexOf(element.value) !== -1;
      });
      this.updateChecked(2);
    },
    packageSelectClick(val) {
      this.packageSelected = val;
      if (val.days === 90) {
        this.clickChecked(2, 0);
        this.optionSheet[0].price = 0;
        this.optionSheet[0].disabled = true;
      }
      this.updatePrice();
      this.discount_code = "";
      this.codeStatus = null;
    },
    addOnSelectClick() {
      this.updatePrice();
      this.discount_code = "";
      this.codeStatus = null;
    },
    updatePrice(discount = 0) {
      // this.price = 0;
      this.price = Math.ceil(this.getTotalPrice - discount);
      this.add_date = this.packageSelected ? this.packageSelected.days : 0;
    },
    checkCodeStatus() {
      var price = this.getTotalPrice;
      getCheckCode(this.discount_code)
        .then((res) => {
          // if (res.status === 200 && res.data.success && price >= 717) {
          if (res.status === 200 && res.data.success) {
            // console.log(res.data.results)
            this.codeStatus = true;
            switch (res.data.results.condition1) {
              case 1:
                this.$nextTick(() => {
                  this.updatePrice((res.data.results.condition2 / 100) * price);
                });
                break;
              case 2:
                this.$nextTick(() => {
                  let dc = res.data.results.condition2;
                  if (price <= res.data.results.condition2) {
                    dc = price;
                  }
                  this.updatePrice(dc);
                });
                break;
              default:
                break;
            }
          } else {
            this.$nextTick(() => {
              this.codeStatus = false;
              this.updatePrice();
            });
          }
        })
        .catch(function (error) {
          // catch
          console.error(error);
        });
    },
    getSubjectText(index) {
      // const list = index <= 4 ? this.options : this["optionMore" + this.$store.state.department];
      var list = [];
      if (index <= 4)
        list = this.options;
      else if (index < 999)
        list = this["optionMore" + this.$store.state.department];
      else if (index == 999)
        list = this["optionSheet"];
      else
        list = this["optionMoreSheet" + this.$store.state.department];
      var text;
      list.forEach((element) => {
        if (element.value === index) {
          text = element.text;
        }
      });
      return text;
    },
    // getSheetText(index) {
    //   const list =
    //     index <= 4
    //       ? this.options
    //       : this["optionMoreSheet" + this.$store.state.department];
    //   var text;
    //   list.forEach((element) => {
    //     if (element.value === index) {
    //       text = element.text;
    //     }
    //   });
    //   return text;
    // },
    scrollToElement(ref, val) {
      const el = this.$refs[`${ref}`];
      if (el && val) {
        scrollIntoView(el, {
          behavior: "smooth",
          scrollMode: "always",
        });
      }
    },
    submitPay(item, promptpay = false) {
      var price = this.price * 100;
      if (promptpay === false)
        price *= 1.04;
      const mergeSelected = this.selectedAct.concat(this.selectedSheetAct);
      var days = 0;
      try {
        days = this.packageSelected.days;
      } catch (error) {
        days = 0;
      }
      var payload = {
        // username: this.$store.state.username,
        name: item.name,
        promptpay: promptpay,
        subject: mergeSelected,
        exp: this.add_date,
        price: price,
        days: days,
      };
      if (this.codeStatus && this.codeStatus !== null) {
        payload.code_id = this.discount_code;
      }
      if (this.$store.state.username) {
        this.$store.commit("POST_OMISE", payload);
      } else {
        payload.user_id = this.email;
        this.$store.commit("POST_OMISE_EMAIL", payload);
      }
    },
  },
  computed: {
    isValidEmail() {
      if (this.$store.state.username) return true;
      var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(this.email);
    },
    userPackage() {
      return this.$store.state.userPackage;
    },
    packageDisable() {
      return this.userPackage.exp
        ? moment(this.userPackage.exp).isAfter(moment())
        : false;
    },
    subject_list() {
      return this.$store.state.topic[parseInt(this.$store.state.department) + 1]
        .list;
    },
    getTotalPrice() {
      var price = parseInt(
        this.packageSelected ? this.packageSelected.total : 0
      );
      this["optionMoreSheet" + this.$store.state.department].forEach((element) => {
        if (this.selectedAct.includes(element.value)) {
          price += element.price;
        }
      });
      if (this.selectedAct && this.selectedAct.length > 0) {
        this.options.forEach((element) => {
          if (this.selectedAct.includes(element.value)) {
            price += element.price;
          }
        });
        this["optionMore" + this.$store.state.department].forEach((element) => {
          if (this.selectedAct.includes(element.value)) {
            price += element.price;
          }
        });
        this.optionSheet.forEach((element) => {
          if (this.selectedAct.includes(element.value)) {
            price += element.price;
          }
        });
      }
      if (this.selectedSheetAct && this.selectedSheetAct.length > 0) {
        this.optionSheet.forEach((element) => {
          if (this.selectedSheetAct.includes(element.value)) {
            price += element.price;
          }
        });
      }
      return price;
    },
    freeAddOnItem() {
      return (
        (this.packageSelected ? this.packageSelected.addOns : 0) -
        this.selectedAct.length
      );
    },
    selectedAct() {
      return this.addOnSelected.filter(
        (x) => !this.userPackage.subject.includes(x)
      );
    },
    selectedSheetAct() {
      return this.sheetSelected.filter(
        (x) => !this.userPackage.subject.includes(x)
      );
    },
  },
  watch: {
    department(val) {
      console.log(val)
      this.$store.state.department = '' + val;
      this.initAll()
    },
    "$store.state.qr": function (val) {
      if (val) {
        this.$refs.modal_qr.show();
      }
    },
    discount_code(val) {
      if (val) {
        this.discount_code = this.discount_code.toUpperCase();
      }
    },
  },
};
</script>

<style scoped>
.border {
  border: 3px solid #89cf99;
  border-radius: 5px;
}

.gallery-item {
  display: inline-block;
}

.gallery-container {
  display: flex;
  flex-direction: row;
  height: 200px;
  overflow-x: scroll;
}

.refund-list {
  border: 3px solid #90cbe9;
  border-radius: 5px;
}
</style>